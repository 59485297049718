<template>
  <div class="cpt-MMC_Gimbal_Z20" :style="containerStyle">
    <div class="hd" v-interact>
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">高清变焦相机</div>
      </div>
      <div @click="$emit('close')" class="close">关闭</div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">控制速度</div>
          <div class="input-box">
            <div class="speedC">
              <div class="speed" :style="
                control_speed == index + 1
                  ? 'background: #FFFFFF;color: #333333;'
                  : ''
              " v-for="(item, index) in new Array(5).fill(1)" :key="index" @click="control_speed = index + 1">
                {{ index + 1 }}
              </div>
            </div>
            <!-- <el-select
              v-model="control_speed"
              size="mini"
              placeholder="请选择控制速度"
            >
              <el-option
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                :label="index + 1"
                :value="index + 1"
              ></el-option>
            </el-select> -->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">云台模式</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div class="mono-left mono_box" :class="{ active: holderModelDomV2 == 0 }"
                  @click="handle_change_ptz_mode(0)"></div>
                <div class="mono-middle mono_box" :class="{ active: holderModelDomV2 == 1 }"
                  @click="handle_change_ptz_mode(1)"></div>
                <div class="mono-right mono_box" :class="{ active: holderModelDomV2 == 2 }"
                  @click="handle_change_ptz_mode(2)"></div>
              </div>
              <div class="text">
                <div class="text-left">锁定</div>
                <div class="text-right">跟随</div>
                <div class="text-right">回中</div>
              </div>
            </div>
            <!-- <el-radio-group @change="handle_change_ptz_mode" v-model="ptz_mode">
              <el-radio
                size="mini"
                v-for="item in drd.ptz_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group> -->
          </div>
        </div>
        <!--  -->
        <div class="form-item">
          <div class="label-box">HDR</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div class="mono-left mono_box" :class="{ active: holderModelDom == 0 }"
                  @click="handle_change_HDR_mode(0)"></div>
                <div class="mono-middle mono_box" :class="{ active: holderModelDom == 1 }"
                  @click="handle_change_HDR_mode(1)"></div>
                <div class="mono-right mono_box" :class="{ active: holderModelDom == 2 }"
                  @click="handle_change_HDR_mode(2)"></div>
              </div>
              <div class="text">
                <div class="text-left">锁定</div>
                <div class="text-right">跟随</div>
                <div class="text-right">回中</div>
              </div>
            </div>
            <!-- <el-radio-group @change="handle_change_HDR_mode" v-model="HDR_mode">
              <el-radio
                size="mini"
                v-for="item in drd.HDR_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group> -->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">相机模式</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_camera_mode" v-model="camera_mode">
              <el-radio size="mini" v-for="item in drd.camera_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <!--  -->
        <div class="form-item">
          <div class="label-box">透雾</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_fog_mode" v-model="fog_mode">
              <el-radio size="mini" v-for="item in drd.fog_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;焦
          </div>
          <div class="input-box">
            <div class="mr10" style="cursor: pointer" @mousedown="handle_zoom(-1)" @mouseup="stopChange"
              @mouseout="stopChange">
              <span class="iconfont icon-shanchu3"></span>
            </div>
            <!-- <el-button
              @click="handle_zoom(-1)"
              size="mini"
              icon="el-icon-minus"
              circle
            ></el-button> -->
            <el-slider v-model="value" :max="50" :min="0" :show-tooltip="false" style="width: 10px" @change="stopChange"
              @input="onChangezoom"></el-slider>
            <div class="ml10" style="cursor: pointer" @mousedown="handle_zoom(1)" @mouseup="stopChange"
              @mouseout="stopChange">
              <span class="iconfont icon-tianjia1"></span>
            </div>
            <!-- <el-button
              @click="handle_zoom(1)"
              size="mini"
              icon="el-icon-plus"
              circle
            ></el-button> -->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            俯&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;仰
          </div>
          <div class="input-box">
            <div class="speedC">
              <div class="pitch" :style="
                pitch == item.num ? 'background: #FFFFFF;color: #333333;' : ''
              " v-for="(item, index) in regList" :key="index" @click="handle_change_pitch(item.num)">
                {{ item.val }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="form-item">
          <div class="label-box">
            航&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;向
          </div>
          <div class="input-box">
            <el-input
              v-model.number="yaw"
              size="mini"
              placeholder="请输入航向"
            />
            <span class="iconfont icon-shanchu3" @click="yaw--"></span>
            <span class="iconfont icon-tianjia1 mr5 ml3" @click="yaw++"></span>
            <el-button @click="handle_change_yaw" size="mini" type="primary"
              >设置</el-button
            >
          </div>
        </div> -->
        <div class="form-item">
          <div class="label-box">防抖</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_ani_mode" v-model="ani_mode">
              <el-radio size="mini" v-for="item in drd.ani_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">OSD</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_osd_mode" v-model="osd_mode">
              <el-radio size="mini" v-for="item in drd.osd_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">低延</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_delay_mode" v-model="delay_mode">
              <el-radio size="mini" v-for="item in drd.delay_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <input class="btn btn-default" type="button" value="PID设置" @click="temperature_correct_get_current_value()" />
        </div>
      </div>
      <div class="rocker-wrap">
        <div class="item-group">
          <div @click="handle_take_photo" class="item-box">
            <el-tooltip class="item" effect="dark" content="拍照" placement="top">
              <div class="icon-box">
                <!-- <span class="iconfont icon-paizhao1"></span> -->
                <img src="~@/assets/images/mount/photo.png" alt="">
              </div>
            </el-tooltip>
          </div>
          <div @click="handle_record" class="item-box">
            <el-tooltip class="item" effect="dark" content="录像" placement="top">
              <div class="icon-box">
                <img src="~@/assets/images/mount/record.png" alt="">
                <!-- <span class="iconfont icon-dandianshipin"></span> -->
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="rocker" ref="rocker">
          <div class="shangUp" @mousedown="fangxiang('up')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"></div>
          <div class="xaiUp" @mousedown="fangxiang('down')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"></div>
          <div class="zuoUp" @mousedown="fangxiang('left')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"></div>
          <div class="youUp" @mousedown="fangxiang('right')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cal_crc_table, MMC_Gimbal_Z20 } from "../utils";
export default {
  props: {
    containerStyle: {
      type: Object,
      default: () => ({}),
    },
    position: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      holderModelDomV2: 1,
      holderModelDom: 0,
      radio: 1,
      value: 25,
      value2: 25,
      control_speed: 3,
      ptz_mode: 0,
      HDR_mode: 0,
      zoom: 1,
      pitch: 0,
      yaw: 0,
      camera_mode: 0,
      fog_mode: 0,
      ani_mode: 0,
      osd_mode: 0,
      delay_mode: 0,
      record: false,
      regList: [
        {
          val: "复位",
          num: 0,
        },
        {
          val: "30",
          num: -30,
        },
        {
          val: "60",
          num: -60,
        },
        {
          val: "90",
          num: -90,
        },
      ],
      drd: {
        ptz_mode: [
          { id: 0, label: "锁定" },
          { id: 1, label: "跟随" },
          { id: 2, label: "回中" },
        ],
        camera_mode: [
          { id: 0, label: "正常" },
          { id: 1, label: "夜间" },
        ],
        HDR_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "HDR" },
          { id: 2, label: "HDR+" },
        ],
        fog_mode: [
          { id: 1, label: "关闭" },
          { id: 0, label: "打开" },
        ],
        ani_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        osd_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        delay_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
      },
      timer: null,
    };
  },
  watch: {
    payload: {
      handler(value) {
        this.resolve_payload(value);
      },
      deep: true,
    },
  },
  methods: {
    onChangezoom(e) {
      if (this.value2 < this.value) {
        this.handle_zoom(1);
      } else if (this.value2 > this.value) {
        this.handle_zoom(-1);
      }
      this.value2 = e;
    },
    stopChange() {
      this.value = 25;
      this.value2 = 25;
      // this.handle_zoom(this)
      let buffer = MMC_Gimbal_Z20.zoom((this.zoom = 1));
      this.commit_directive(buffer);
    },
    resolve_payload(buff) {
      let dataArray = new Uint8Array(buff);
      if (
        dataArray[0] === 0xa5 &&
        cal_crc_table(dataArray) === dataArray[dataArray.length - 1]
      ) {
        switch (dataArray[1]) {
          case 0x01:
            this.msg_key_status1(dataArray);
            break;
          case 0xf8:
            this.gcs_button_ctrl(dataArray);
            break;
        }
      }
    },
    gcs_button_ctrl(data) {
      let button = data[3];
      let status = data[4];

      switch (button) {
        case 4:
          this.gimbal_mode_ctrl(status);
          break;
        default:
          break;
      }
    },
    msg_key_status1(data) {
      this.gimbal_mode_ctrl(data[7] & 0x3);
    },
    gimbal_mode_ctrl(mode) {
      this.ptz_mode = mode;
    },
    temperature_correct_get_current_value() {
      var buffer = new Array(0xa5, 0x0d, 0x05, 0x65, 0x74, 0xf5);
      this.commit_directive(buffer);
    },
    handle_change_ptz_mode(value) {
      this.holderModelDomV2 = value
      let buffer = MMC_Gimbal_Z20.gimbal_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_HDR_mode(value) {
      this.holderModelDom = value
      let buffer = MMC_Gimbal_Z20.gimbal_HDR_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_ani_mode(value) {
      let buffer = MMC_Gimbal_Z20.gimbal_ani_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_osd_mode(value) {
      let buffer = MMC_Gimbal_Z20.gimbal_osd_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_delay_mode(value) {
      let buffer = MMC_Gimbal_Z20.gimbal_delay_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_zoom(value) {
      console.log(value);
      let buffer = MMC_Gimbal_Z20.zoom(this.zoom + value);
      this.commit_directive(buffer);
      // buffer = MMC_Gimbal_Z20.zoom((this.zoom = 1));
      // this.commit_directive(buffer);
    },
    handle_change_pitch(num) {
      this.pitch = num
      let buffer = MMC_Gimbal_Z20.change_pitch_angle(this.pitch);
      this.commit_directive(buffer);
    },
    handle_change_yaw() {
      let buffer = MMC_Gimbal_Z20.change_yaw_angle(this.yaw);
      this.commit_directive(buffer);
    },
    handle_change_camera_mode(value) {
      let buffer = MMC_Gimbal_Z20.camera_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_fog_mode(mode) {
      let buffer = MMC_Gimbal_Z20.fog_mode_ctrl(mode);
      this.commit_directive(buffer);
    },
    handle_take_photo() {
      let { position } = this;
      let buffer = MMC_Gimbal_Z20.take_photo(position || {});
      this.commit_directive(buffer);
      this.$emit("take_photo","MMC_Gimbal_Z20")
    },
    handle_record() {
      this.record = !this.record;
      let buffer = MMC_Gimbal_Z20.record(this.record);
      this.commit_directive(buffer);
    },
    commit_directive(buffer) {
      this.$emit("directive", buffer);
      console.log(buffer);
    },
    stopfxFn() {
      let buffer = null;
      buffer = MMC_Gimbal_Z20.gimbal_pitch_ctrl(0, this.control_speed);
      this.commit_directive(buffer);
      buffer = MMC_Gimbal_Z20.gimbal_yaw_ctrl(0, this.control_speed);
      this.commit_directive(buffer);
    },
    fangxiang(type) {
      let buffer = null;
      switch (type) {
        case "up":
          console.log(this.control_speed);
          buffer = MMC_Gimbal_Z20.gimbal_pitch_ctrl(1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "right":
          buffer = MMC_Gimbal_Z20.gimbal_yaw_ctrl(1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "left":
          buffer = MMC_Gimbal_Z20.gimbal_yaw_ctrl(-1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "down":
          buffer = MMC_Gimbal_Z20.gimbal_pitch_ctrl(-1, this.control_speed);
          this.commit_directive(buffer);
      }
      // this.timer = setTimeout(() => {
      //   clearTimeout(this.timer);
      //   buffer = MMC_Gimbal_Z20.gimbal_pitch_ctrl(0, this.control_speed);
      //   this.commit_directive(buffer);
      //   buffer = MMC_Gimbal_Z20.gimbal_yaw_ctrl(0, this.control_speed);
      //   this.commit_directive(buffer);
      // }, 500);
    },
    init_rocker() {
      let buffer = null;
      joystick({
        // zone: this.$refs["rocker"],
        // mode: "static",
        // position: { left: "50%", top: "50%" },
        // color: "#9bacbe",
        onstart: (type) => {
          switch (type) {
            case "up":
              console.log(this.control_speed);
              buffer = MMC_Gimbal_Z20.gimbal_pitch_ctrl(1, this.control_speed);
              this.commit_directive(buffer);
              break;
            case "right":
              buffer = MMC_Gimbal_Z20.gimbal_yaw_ctrl(-1, this.control_speed);
              this.commit_directive(buffer);
              break;
            case "left":
              buffer = MMC_Gimbal_Z20.gimbal_yaw_ctrl(1, this.control_speed);
              this.commit_directive(buffer);
              break;
            case "down":
              buffer = MMC_Gimbal_Z20.gimbal_pitch_ctrl(-1, this.control_speed);
              this.commit_directive(buffer);
          }
        },
        onend: () => {
          buffer = MMC_Gimbal_Z20.gimbal_pitch_ctrl(0, this.control_speed);
          this.commit_directive(buffer);
          buffer = MMC_Gimbal_Z20.gimbal_yaw_ctrl(0, this.control_speed);
          this.commit_directive(buffer);
        },
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_Z20 {
  position: absolute;
  top: -1px;
  right: 0;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  width: 470px;
  height: 290px;
  box-sizing: border-box;

  // padding: 10px 20px;
  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;

      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(135deg,
            #e3aa77 0%,
            #f5cda9 38%,
            #f9ecd3 58%,
            #fcdbb1 79%,
            #edb07a 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .bd {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    height: 230px;
    overflow-x: hidden;
    .form-wrap {
      flex-shrink: 0;

      .form-item {
        display: flex;
        align-items: center;
        height: 40px;

        .label-box {
          color: #cbd0eb;
          width: 75px;
        }

        .btn-default {
          padding: 0 10px !important;
          background: #004FFF;
          border-color: #004FFF;
        }

        .input-box {
          display: flex;
          align-items: center;

          .mono {
            display: flex;
            flex-direction: column;

            .mono-long {
              display: flex;
              justify-content: space-between;
              width: 168px;
              height: 17px;
              background: url("~@/assets/images/mount_long.png") no-repeat;
              background-size: 100%;
              margin-bottom: 5px;

              .mono_box {
                width: 28px;
                height: 13px;
                border-radius: 3px;
                margin-top: 2px;
                cursor: pointer;
              }

              .active {
                background: url("~@/assets/images/mount/mount_control.png") no-repeat !important;
                background-size: 100% 100%;
              }
            }

            .text {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
            }
          }
        }
      }
    }

    .rocker-wrap {
      flex: 1;

      .item-group {
        display: flex;
        justify-content: space-evenly;

        .item-box {
          .icon-box {
            width: 32px;
            height: 32px;
            background: #004FFF;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            .iconfont {
              font-size: 24px;
              color: #dce9ff;
              cursor: pointer;
            }
          }
        }
      }

      .rocker {
        margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto;
        background: center url("~@/assets/images/accident/caozuo.png") no-repeat;
        background-size: 100% 100%;

        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;

    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #004FFF;
        color: #dce9ff;
      }
    }
  }

  .el-input {
    width: 165px;
    margin-right: 5px;

    .el-input__inner {
      background: #000000;
      border: 1px solid #004FFF;
      color: #dce9ff;
    }
  }

  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #004FFF;
            background: #004FFF;
          }
        }
      }

      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }

  .el-button {
    background: #004FFF;
    color: #000;
    border: none;
  }
}

/deep/.el-slider__bar {
  background: #fff;
}

.speedC {
  width: 203px;
  height: 24px;
  display: flex;
  background: rgba(13, 34, 79, 0.65);
  border: 1px solid #004FFF;
  border-radius: 12px;

  .speed {
    width: 41px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }

  .pitch {
    font-size: 12px;
    margin-left: 12px;
    width: 34px;
    height: 24px;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
    color: #fff;
  }
}

.iconfont {
  color: #004FFF;
}

// 变焦功能
/deep/ .el-slider__runway {
  visibility: hidden;
}
</style>